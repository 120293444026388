:root {
  --background: #ffffff;
  --text-primary: #000;
  --text-secondary: #41c205;
  --accent: #41c205;
  --border: #333;
  --form-bg: #fff;
}

[data-theme='dark'] {
  --background: #000;
  --text-primary: #fff;
  --text-secondary: #41c205;
  --accent: #41c205;
  --border: #808080;
  --form-bg: #191919;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  background-color: var(--background);
  color: var(--text-primary);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: .8rem;
  transition: all .5s;
}